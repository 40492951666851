import { render, staticRenderFns } from "./NewsletterSignup1.vue?vue&type=template&id=b8ad7120"
import script from "./NewsletterSignup1.vue?vue&type=script&lang=js"
export * from "./NewsletterSignup1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MPowerValidateInput: require('/codebuild/output/src1479718996/src/client/components/MPowerValidateInput.vue').default})
